const dev = {
  STRIPE_KEY: "pk_test_51IKuQZEXpcLkV0UTVnDXs9dt1LpzTVQEL5luVRNTomTsnm6dBHemK0wpreuw9kQ2VFpFixwM462jJRrUSh833Q6Q00NT98rISl",
  s3: {
    REGION: "us-east-1",
    BUCKET: "dev-parkinhighrise-infra-s3-uploads4f6eb0fd-1bhdztnp4oxjy"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://9s41h5j3ld.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Y6f9SgjD6",
    APP_CLIENT_ID: "4ftuvb6o48q6cmosd8tfqhoump",
    IDENTITY_POOL_ID: "us-east-1:a2c38e8f-9e27-4403-a99f-cba13c7db9f3"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_51IKuQZEXpcLkV0UTVnDXs9dt1LpzTVQEL5luVRNTomTsnm6dBHemK0wpreuw9kQ2VFpFixwM462jJRrUSh833Q6Q00NT98rISl",
  s3: {
    REGION: "us-east-1",
    BUCKET: "prod-parkinhighrise-infra-s3-uploads4f6eb0fd-n0qlq93xiyfo"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://3nkpblqocd.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_39LgE05gi",
    APP_CLIENT_ID: "7hontgm6d278ahalh4r6cssf85",
    IDENTITY_POOL_ID: "us-east-1:6d062507-a270-4f89-bf59-26f767a664f2"
  }
};


const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;